import React, { useState, useEffect } from "react";
import "./PosBill.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Table } from "react-bootstrap";
import Header from "./Header";
import Peer from "peerjs";
import Reedem from "./Reedem";
import PaymentMethod from "./PaymentMethod";
import PayLater from "./PayLater";
import QrCode from "./QrCode";
import Coupon from "./Coupon";
import logonew from "../images/vasyerplogo.png"
import { BsArrowRight } from "react-icons/bs";
import Swal from "sweetalert2";
import cfdicon from "../images/cfddsisplay.png";
import Footer from "./Footer";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "font-awesome/css/font-awesome.min.css";

function ClientSideBill({ socket, username, room, connection }) {

  const billDetailsDefault = {
    "totalqty" : 0,
    "roundoff" : 0,
    "totaldiscount" : 0,
    "subtotal" : 0,
    "additionalcharge" : 0,
    "taxamount" : 0,
    "flatdiscount" : 0,
    "totalMrp" : 0,
    "creditnote_net_Amount" : 0,
    "creditnote_applier_amount" : 0,
    "credit_net_total_amount":0
  }
  const [messageList, setMessageList] = useState([]);
  const [product, setProduct] = useState([]);
  const [imgsrc, setImgSrc] = useState(false);
  const [showQrCode, setShowQrCode] = useState(false);
  const [hideConnectionScreen, setHideConnectionScreen] = useState(false);
  const [isConnected, setIsConnected] = useState(false);
  const [showBillScreen, setShowBillScreen] = useState(false);
  const [receiverId, setReceiverId] = useState("");
  const [customerdata, setCustomerData] = useState([]);
  const [billDetail, setBillDetail] = useState(billDetailsDefault);
  const [isLoading, setIsLoading] = useState(false);
  const [bannerimages, setBannerImages] = useState([]);
  const [logoimages, setLogoImages] = useState([]);
  const [currancy, setCurrancy] = useState();
  const [srno,setSrNo]=useState([]);
  let productArrayDefaultist = [];
  useEffect(() => {
    setTimeout(() => {
      if (messageList.length === 0 && customerdata.length===0 && bannerimages.length > 0) {
        setShowBillScreen(false)
      }else{
        setShowBillScreen(true)
      }
    }, 3000);
  }, [messageList,customerdata])
  

  var conn = null;
  var peer = null;
  var lastPeerId = null;
 
  const fetchData = () => {
    return fetch("https://fakestoreapi.com/products")
      .then((res) => res.json())
      .then((json) => {
        console.log(json);
        setProduct(json);
      });
  };
  initialize();



  /**
   * Create the Peer object for our end of the connection.
   *
   * Sets up callbacks that handle any events related to our
   * peer object.
   */
  function initialize() {
    peer = new Peer(null, {
      debug: 2,
      reconnect: {
        myPeerId: true,
      },
    });

    peer.on("open", function (id) {
      // Workaround for peer.reconnect deleting previous id
      if (peer.id === null) {
        console.log("Received null id from peer open");
        peer.id = lastPeerId;
      } else {
        lastPeerId = peer.id;
      }
    });

    peer.on("connection", function (c) {
      // Disallow incoming connections
      c.on("open", function () {
        Swal.fire({
          position: "top-middle",
          icon: "error",
          title: "Connected to CFD",
          showConfirmButton: false,
          timer: 1500,
        });

        c.send("Sender does not accept incoming connections");
        setTimeout(function () {
          c.close();
        }, 500);
      });
    });
    peer.on("disconnected", function () {
      console.log('Connection lost. Please reconnect');
      // Workaround for peer.reconnect deleting previous id
      peer._lastServerId = lastPeerId;
      peer.reconnect();
    });
    peer.on("close", function () {
      conn = null;
      // status.innerHTML = "Connection destroyed. Please refresh";
      peer.reconnect();
      Swal.fire({
        position: "top",
        icon: "error",
        title: "Connected Closed",
        showConfirmButton: false,
        timer: 1500,
      });
      console.log("Connection destroyed");
    });
    peer.on("error", function (err) {

      console.log(err);
    });
  }

  /**
   * Create the connection between the two Peers.
   *
   * Sets up callbacks that handle any events related to the
   * connection and data received on it.
   */
  function join() {
    // Close old connection
    // initialize()

    let printnewmsg = document.createElement("img");
    if (conn) {
      conn.close();
    }
    
    console.log(peer);
    console.log(conn);
    // Create connection to destination peer specified in the input field
 
    conn = peer.connect(receiverId, {
      reliable: true,
    });
    console.log(conn, "connection");
    conn.on("open", function () {
      // status.innerHTML = "Connected to: " + conn.peer;
      console.log("Connected to: " + conn.peer);
      Swal.fire({
        position: "top-middle",
        icon: "success",
        title: "Connected to CFD",
        showConfirmButton: false,
        timer: 1500,
      });

      setIsConnected(true);

      var command = getUrlParam("command");
      if (command) conn.send(command);
    });
  

    conn.on("data", function (data) {
      console.log("Data recieved");
      console.log("data", data);
      const parse_data = JSON.parse(data);
      console.log("parsedata", parse_data);
      console.log("parseID", parse_data.type);
     
     
      switch (parse_data.type) {
       
        case "addproductbtn":
          console.log("productname", data);
          const productVos = [...parse_data.data.product];
          setMessageList(productVos.reverse());
          console.log(messageList.length,"parse_data::::::::");
          const billDetail = parse_data.data.billdetail;
          setBillDetail(billDetail); 
          setShowBillScreen(true);
          break;

          case "editproductbtn":
          console.log("productname", data);
          const editProductVos = [...parse_data.data.product];
          const editedProductVosWithoutNaN = editProductVos.filter(product => product.orignalPrice !== 'NaN');
          console.log(editedProductVosWithoutNaN,"editedProductVosWithoutNaN");
          setMessageList(editedProductVosWithoutNaN);
          const editBillDetail = parse_data.data.billdetail;
          setBillDetail(editBillDetail);
          setShowBillScreen(true);
          break;
          
    
        case "cardbtn":
          console.log(PaymentMethod, "image");
          setShowBillScreen(true);
          setShowQrCode(true);
          setImgSrc(<PaymentMethod type={parse_data.type} />);
          break;
          case "SaveCashFromtendered_btn":
            console.log("image", data);
            setShowBillScreen(true);
            setShowQrCode(true);
            setImgSrc(<PaymentMethod type={parse_data.type} />);
            break;
        case "upibtn":
          console.log("image", parse_data.data.qrcode);
          setShowBillScreen(true);
          setShowQrCode(true);
          setImgSrc(<QrCode qrcodeimg={parse_data.data.qrcode} setImgSrc={setImgSrc}/>);

          break;
        case "applycreditbtn":
          console.log("image", data);
          setShowBillScreen(true);
          setShowQrCode(true);
          setImgSrc(
            <Reedem
              creditnoteid={parse_data.data.redeem.creditId}
              creditnoteamount={parse_data.data.redeem.creditamount}
              creditavailableamount={parse_data.data.redeem.availableamount}
            />
          );

          setBillDetail( parse_data.data.billdetail);
          
          console.log(parse_data.data.redeem.creditId, "redeem credit here");
          console.log(
            parse_data.data.redeem.creditamount,
            "redeem credit amount"
          );
          console.log(
            parse_data.data.redeem.availableamount,
            "available amount..."
          );
          console.log("image workij dnaanksls", Reedem);

          break;

        case "paylater_termsadd_modelbtn":
          console.log("image", data);
          setShowBillScreen(true);
          setShowQrCode(true);
          setImgSrc(
            <PayLater
              terms={parse_data.data.paylater.terms}
              paylaterdate={parse_data.data.paylater.duedate}
            />
          );
          console.log(parse_data.data.paylater.terms, "terms------");
          console.log(parse_data.data.paylater.duedate, "due date-----");

          break;
        case "couponbtncfd":
          console.log("image", data);
          setShowBillScreen(true);
          setShowQrCode(true);
          setImgSrc(<Coupon />);

          break;

        case "contactVo":
          
         if(parse_data.data.customer!=null){
          let len = parse_data.data.customer.data.contact.length;
          setCustomerData([
            parse_data.data.customer.data.contact[0],
            parse_data.data.customer.data.contact[len - 1],
            parse_data.data.customer.data.loyaltypoints,
          ]);
         
         
          setShowBillScreen(true)
          console.log(
            parse_data.data.customer.data.loyaltypoints,
            "loyalty points --=-----"
          );
          console.log(
            parse_data.data.customer.data.contact[0],
            "customer data --------------"
          );

        }else{
          setCustomerData([]);
         }

          break;

        case "removeProduct":
          console.log("removeProduct", data);
          const actualProductList = messageList.filter(
            (x) => x.id !== parse_data.product.id
          );
          setMessageList(actualProductList.reverse());
          console.log(parse_data.data, "dataaaaaaaaa");
          break;
        case "clearScreenbtn":
          
          setShowQrCode(false);
          setImgSrc(false);
          setMessageList([]);
          setCustomerData([]);
          setBillDetail(billDetailsDefault)

          break;
        case "banners":
          setShowBillScreen(false);
          setShowQrCode(false);
          // setImgSrc(<Coupon />);
          console.log("LIST OF BANNERS", parse_data.data.bannerlist[0]);
          setBannerImages(parse_data.data.bannerlist[0]);
          if(parse_data.data.bannerlist[0].length == 0){
            setShowBillScreen(true);
          }

          break;

          case "logocfd":
          setShowQrCode(false);
          setCurrancy(parse_data.currency);
          setLogoImages(parse_data.data.logocfdobj[0]);
          break;

          case "footerCalculation":
            console.log("Calculation", parse_data);
          console.log("Calculation", parse_data.data.footerCalculation);
          setBillDetail(parse_data.data.footerCalculation);
          break;

        default:
          console.log("default");
          addMessage(data);
          break;
      }
    });

    conn.on("close", function () {
      // status.innerHTML = "Connection closed";
      alert("connection closed");
    });
    peer.on("error", function (err) {
      console.log(err);
      alert("" + err);
    });
  }

  console.log("MESSAAGE LIST YASHVIII outerrrrrrrr", messageList);

  /**
   * Get first "GET style" parameter from href.
   * This enables delivering an initial command upon page load.
   *
   * Would have been easier to use location.hash.
   */
  function getUrlParam(name) {
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    var regexS = "[\\?&]" + name + "=([^&#]*)";
    var regex = new RegExp(regexS);
    var results = regex.exec(window.location.href);
    if (results == null) return null;
    else return results[1];
  }

  useEffect(() => {
    fetchData();
  }, []);
  let itemstotal = 0;
  messageList.forEach((item) => {
    console.log("items ::", item);
    itemstotal += parseInt(item.netAmount);
  });
  console.log(parseFloat(itemstotal.toFixed(2)));

  function addMessage() {
    console.log("messages added");
  }
  console.log(messageList, "listtttttttttt");
  const handleClick = () => {
    console.log(receiverId,"receiveid inputtttt :::::::::::::::::");
    setIsLoading(true);
    join();
    showMainScreenFnc();
    // You can replace this with your actual connect logic
    setTimeout(() => {
      setIsLoading(false);
    }, 2000); 
  };

  const showMainScreenFnc = () => {
    setHideConnectionScreen(true);
  };


  const settings = {
    dots: false,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
  pauseOnHover: false
  };
  //testing

  return (
    <>
      {hideConnectionScreen && isConnected ? (
        <>
          {!showBillScreen ? (
            <Container
             fluid
              style={{ backgroundColor: "gray", padding: "0px"}}
            >
              {!showBillScreen ? (
                <Slider {...settings}>
                {bannerimages.map((image, index) => (
                  <img
                    className="d-block w-100 banner-img"
                    src={image.signedSrc}
                    alt=""
                    key={index}
                    style={{ width: "100vw", height: "100vh" }}
                  />
                ))}
              </Slider>
              ) : (<div></div>)}
              
            </Container>
          ) : (
            <Container fluid>
              <Header customerdetail={customerdata} />
              <Row>
                <Col className="bill-left-side" xs={12} md={8} >
                 
                    <div className="bill-profile-side"style={{ maxHeight: '500px', overflowY: 'auto' }}>
                      <Table >
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Items</th>
                            <th>MRP</th>
                            <th>Price</th>
                            <th>Qty</th>
                            <th>Total</th>
                          </tr>
                        </thead>

                        <tbody className="bill-table-body"  >
                          {messageList.map((product, index) => (
                            <tr>
                              <td>
                                <span className="items-id">{index + 1}</span>
                              </td>
                              <td>{product.productName}</td>
                              <td className="td-text-right">
                              <span dangerouslySetInnerHTML={{ __html: currancy }}></span>  
                                {product.mrp}
                              </td>
                              <td className="td-text-right">
                              <span dangerouslySetInnerHTML={{ __html: currancy }}></span>
                                {product.orignalPrice}
                              </td>
                              <td className="td-text-right">{product.qty}</td>
                              <td className="td-text-right">
                              <span dangerouslySetInnerHTML={{ __html: currancy }}></span>
                                {product.netAmount}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  <Footer billDetail={billDetail} currancy={currancy}/>
                </Col>

                <Col className="bill-right-side" xs={6} md={4}>
                <h6  style={{ display: !imgsrc ? "block" : "none" }}>Powered By</h6>
                
                  <div
                    className="vasy-img"
                    style={{ display: !imgsrc ? "block" : "none" }}
                  >
                 
                    {
                      (function (){ 
                        console.log("logoimages==-=-=-=>",logoimages)
                        })()
                    }
                    {
                      !logoimages? (
                        <img src={logonew} alt="logo"/>
                      ):(
                        <img src={logoimages.signedSrc} alt="logo" />
                      )
                    }
                          
                       
                  </div>
                  
                  <div
                    className="qr-card-img"
                    style={{ display: showQrCode &&imgsrc ? "block" : "none" }}
                  >
                    {imgsrc}
                  </div>
                </Col>
              </Row>
            </Container>
          )}
        </>
      ) : (
        <body className="connection-container">
          <div className="conatiner ">
            <div className="connection-header">
              <img src={logonew} alt="" style={{width:"11%"}} />
              <BsArrowRight style={{ marginLeft: "24px", fontSize: "4rem" }} />
              <img
                src={cfdicon}
                alt=""
                style={{ marginLeft: "26px", width: "69px" }}
              />
            </div>
            <div className="connection-content">
              <h3>VasyERP Customer Facing Display</h3>
              <p>Login to VasyERP and active the customer display</p>
              <p>
                Once activated,it will display an auto generated code pairing to
                be entered in box below.
              </p>
            </div>

            <div className="connection-div">
            <input type="text" id="receiver-id"
             value={receiverId}
             onChange={(e) => setReceiverId(e.target.value)}
             autoComplete="off"
             autofocus="autofocus"
              />
              <button type="button" onClick={()=> handleClick()}>
                {isLoading ? "Connecting..." : "Connect To Peer"}
              </button>
            </div>
          </div>
        </body>
      )}
    </>
  );
}

export default ClientSideBill;
